// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-example-jsx": () => import("./../../../src/pages/example.jsx" /* webpackChunkName: "component---src-pages-example-jsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-custom-page-tsx": () => import("./../../../src/templates/custom-page.tsx" /* webpackChunkName: "component---src-templates-custom-page-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-events-tsx": () => import("./../../../src/templates/events.tsx" /* webpackChunkName: "component---src-templates-events-tsx" */),
  "component---src-templates-homepage-tsx": () => import("./../../../src/templates/homepage.tsx" /* webpackChunkName: "component---src-templates-homepage-tsx" */),
  "component---src-templates-investment-tsx": () => import("./../../../src/templates/investment.tsx" /* webpackChunkName: "component---src-templates-investment-tsx" */),
  "component---src-templates-investments-tsx": () => import("./../../../src/templates/investments.tsx" /* webpackChunkName: "component---src-templates-investments-tsx" */),
  "component---src-templates-portfolio-tsx": () => import("./../../../src/templates/portfolio.tsx" /* webpackChunkName: "component---src-templates-portfolio-tsx" */),
  "component---src-templates-preview-tsx": () => import("./../../../src/templates/preview.tsx" /* webpackChunkName: "component---src-templates-preview-tsx" */),
  "component---src-templates-team-tsx": () => import("./../../../src/templates/team.tsx" /* webpackChunkName: "component---src-templates-team-tsx" */)
}

