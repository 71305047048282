import { PreviewStoreProvider } from 'gatsby-source-prismic'
import * as React from 'react'

export const shouldUpdateScroll = ({ routerProps }) => {
  return !routerProps.location.state?.disableScrollUpdate
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state)
    location.state.referrer = prevLocation ? prevLocation.pathname : null
}

export const wrapRootElement = ({ element }) => (
  <PreviewStoreProvider initialEnabled={true}>{element}</PreviewStoreProvider>
)
